import { customTwMerge } from '@tailwind-base/utils/custom-tw-merge';
import type { DropdownProps } from 'afterdoc-design-system/components/Atoms/Dropdown/Dropdown';
import Dropdown from 'afterdoc-design-system/components/Atoms/Dropdown/Dropdown';
import TextFieldSelectBox, {
  type TextFieldSelectBoxProps,
} from 'afterdoc-design-system/components/Molecules/TextSelect/TextFieldSelectBox';
import { useCallback, useEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useCloseDropdown } from './hooks/use-close-dropdown';

export interface TextFieldSelectBoxDropdownProps
  extends Omit<TextFieldSelectBoxProps, 'onClick' | 'onSelect' | 'className'> {
  options: DropdownProps['options'];
  id?: string;
  width?: number | string;
  selectedIndex?: number;
  placeholder?: string;
  manualInput?: boolean;
  onSelect: (index: number) => void;
  wrapperClassName?: string;
  selectBoxClassName?: string;
  inputClassName?: string;
  dropdownWrapperClassName?: string;
  dropdownOptionsClassName?: string;
  defaultValue?: string;
  focusedIndex?: DropdownProps['focusedIndex'];
  customFocusScrollHandler?: DropdownProps['customFocusScrollHandler'];
  customSelectedScrollHandler?: DropdownProps['customSelectedScrollHandler'];
}

export default function TextFieldSelectBoxDropdown({
  options: _options,
  id,
  width,
  onSelect,
  placeholder: _placeholder,
  selectedIndex: defaultSelectedIndex,
  manualInput,
  wrapperClassName,
  selectBoxClassName,
  inputClassName,
  dropdownWrapperClassName,
  dropdownOptionsClassName,
  defaultValue,
  focusedIndex,
  customFocusScrollHandler,
  customSelectedScrollHandler,
  ...props
}: TextFieldSelectBoxDropdownProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const ignoreRef = useRef(null);

  const dropdownId = id || uuidv4();

  const [isInputActive, setInputActive] = useState(false);
  const [options, setOptions] = useState(_options);
  const [placeHolder, setPlaceHolder] = useState(_placeholder);
  const [isToggle, setIsToggle] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState<number | undefined>(undefined);
  const [inputValue, setInputValue] = useState(defaultValue || '');

  useCloseDropdown({ dropdownId, setIsToggle });

  const handleSelect = (index: number) => {
    if (options[index] === '직접입력' && manualInput) {
      setInputValue('');
      setPlaceHolder('');
      setSelectedIndex(undefined);
      setInputActive(true);
      setIsToggle(false);
      inputRef.current?.focus();
      return;
    }

    setSelectedIndex(index);
    setInputValue(options[index]);
    setPlaceHolder(_placeholder);
    setInputActive(false);
    setIsToggle(false);

    setTimeout(() => {
      onSelect?.(index);
    }, 0);
  };

  const handleToggle = () => {
    setIsToggle((prev) => {
      const newState = !prev;
      if (newState) {
        document.dispatchEvent(new CustomEvent('closeDropdown', { detail: dropdownId }));
      }
      return newState;
    });
  };

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent) => {
      if (e.key === 'Enter') {
        setIsToggle((prev) => {
          const newState = !prev;
          if (newState) {
            document.dispatchEvent(new CustomEvent('closeDropdown', { detail: dropdownId }));
          } else {
            setIsToggle(true);
          }
          return newState;
        });
      }

      if (e.key === 'Escape' && isToggle) {
        setIsToggle(() => {
          document.dispatchEvent(new CustomEvent('closeDropdown', { detail: dropdownId }));
          return false;
        });
      }
    },
    [dropdownId],
  );

  useEffect(() => {
    setSelectedIndex(defaultSelectedIndex);
  }, [defaultSelectedIndex]);

  useEffect(() => {
    if (manualInput) {
      setOptions([..._options, '직접입력']);
    }
  }, [manualInput, _options]);

  useEffect(() => {
    if (selectedIndex !== undefined && selectedIndex < options.length) {
      setInputValue(options[selectedIndex]);
    } else if (selectedIndex === undefined) {
      setInputValue(defaultValue || '');
    }
  }, [selectedIndex, options, defaultValue]);

  useEffect(() => {
    setOptions(_options);
  }, [_options]);

  useEffect(() => {
    if (!isToggle) {
      setTimeout(() => {
        inputRef.current?.blur();
      }, 0);
    }
  }, [isToggle]);

  return (
    <div className={customTwMerge('relative', wrapperClassName)}>
      <TextFieldSelectBox
        ref={inputRef}
        wrapperRef={ignoreRef}
        width={width}
        placeholder={placeHolder}
        value={inputValue || ''}
        onChange={(e) => setInputValue(e.target.value)}
        inputNotAllowed={!isInputActive}
        onClickInput={handleToggle}
        onKeyDown={handleKeyDown}
        className={customTwMerge(selectBoxClassName)}
        inputClassName={customTwMerge(inputClassName)}
        isToggle={isToggle}
        {...props}
      />
      {isToggle && (
        <Dropdown
          id={dropdownId}
          width={width}
          options={options}
          ignoreRefs={[ignoreRef]}
          handleToggle={handleToggle}
          selectedIndex={selectedIndex}
          onSelect={handleSelect}
          wrapperClassName={dropdownWrapperClassName}
          optionsClassName={dropdownOptionsClassName}
          focusedIndex={focusedIndex}
          customFocusScrollHandler={customFocusScrollHandler}
          customSelectedScrollHandler={customSelectedScrollHandler}
        />
      )}
    </div>
  );
}
