import type { ProgressBarProps } from 'afterdoc-design-system/components/Molecules/ProgressBar/ProgressBar';
import { uniqueId } from 'lodash-es';

export const progressBarService = {
  show(props: ProgressBarProps): string {
    const id = uniqueId();

    const customEvent = new CustomEvent('showProgressBar', { detail: props });
    document.dispatchEvent(customEvent);

    return id;
  },

  hide() {
    const customEvent = new CustomEvent('hideProgressBar');
    document.dispatchEvent(customEvent);
  },
};
