export class ApiError extends Error {
  code: number | undefined;

  constructor(message: string, code?: number) {
    super(message);
    this.code = code !== undefined ? Number(code) : undefined;
  }
}

export interface ApiResponseData<T> {
  code?: number;
  data?: T;
  message?: string;
}

export const checkApiResponse = <T>(response: ApiResponseData<T>): T => {
  if (response.code !== 0 || (typeof response.data !== 'boolean' && !response.data)) {
    throw new ApiError(response.message || 'Unknown error', response.code);
  }
  return response.data;
};

export const isApiErrorWithCode = (
  error: Error | unknown,
  code?: number | number[],
): error is ApiError => {
  if (!(error instanceof ApiError)) {
    return false;
  }

  if (code === undefined) {
    return true;
  }

  if (Array.isArray(code)) {
    return code.includes(error.code || 0);
  }

  return error.code === code;
};
