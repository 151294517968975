import { customTwMerge } from '@tailwind-base/utils/custom-tw-merge';
import { debounce } from 'lodash-es';
import {
  type CSSProperties,
  type ElementType,
  type HTMLAttributes,
  type MouseEventHandler,
  type ReactNode,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import './Scrollbar.scss';

export interface AlwaysVisibleScrollbarProps
  extends Omit<HTMLAttributes<HTMLDivElement>, 'onScroll'> {
  children: ReactNode;
  disabledX?: boolean;
  disabledY?: boolean;
  style?: CSSProperties;
  as?: ElementType;
  onScroll?: (scrollTop: number) => void;
}

const AlwaysVisibleScrollbar = forwardRef<
  {
    scrollTo: (index: number, behavior?: ScrollBehavior) => void;
  },
  AlwaysVisibleScrollbarProps
>(
  (
    { children, className, disabledX, disabledY, as: Children = 'div', style, onScroll, ...props },
    ref,
  ) => {
    const yScrollRef = useRef<HTMLDivElement>(null);
    const xScrollRef = useRef<HTMLDivElement>(null);
    const yScrollbarRef = useRef<HTMLDivElement>(null);
    const xScrollbarRef = useRef<HTMLDivElement>(null);
    const viewPortRef = useRef<HTMLDivElement>(null);

    const scrollMinSize = 30;

    const [isCalculating, setIsCalculating] = useState<boolean>(true);
    const [overflowState, setOverflowState] = useState({ isOverflowY: false, isOverflowX: false });
    const [scrollPosition, setPosition] = useState<{
      yPosition: number;
      xPosition: number;
    }>({
      yPosition: 0,
      xPosition: 0,
    });

    const [scrollSize, setScrollSize] = useState<{
      ySize: number;
      xSize: number;
    }>({
      ySize: 0,
      xSize: 0,
    });

    const [isDragging, setIsDragging] = useState<boolean>(false);

    const finalizeCalculation = useRef(
      debounce(() => {
        setIsCalculating(false);
      }, 200),
    ).current;

    const calculateScrollHeight = useMemo(() => {
      return () => {
        if (viewPortRef.current?.firstElementChild) {
          const computedStyle = getComputedStyle(viewPortRef.current.firstElementChild as Element);
          const paddingTop = Number.parseInt(computedStyle.paddingTop, 10);
          const paddingBottom = Number.parseInt(computedStyle.paddingBottom, 10);
          const totalPadding = paddingTop + paddingBottom;

          const more =
            viewPortRef.current.scrollHeight - viewPortRef.current.clientHeight - totalPadding;
          const percent = Math.round(
            (more / (viewPortRef.current.scrollHeight - totalPadding)) * 100,
          );
          const clientHh = Math.round((viewPortRef.current.clientHeight * percent) / 100);
          const scrollHeight = viewPortRef.current.clientHeight - clientHh;
          return scrollHeight >= viewPortRef.current.scrollHeight
            ? 0
            : Math.max(scrollHeight, scrollMinSize);
        }
        return 0;
      };
    }, [viewPortRef.current]);

    const calculateScrollWidth = useMemo(() => {
      return () => {
        if (viewPortRef.current?.firstElementChild) {
          const computedStyle = getComputedStyle(viewPortRef.current.firstElementChild as Element);
          const paddingLeft = Number.parseInt(computedStyle.paddingLeft, 10);
          const paddingRight = Number.parseInt(computedStyle.paddingRight, 10);
          const totalPadding = paddingLeft + paddingRight;

          const clientWidth = viewPortRef.current.clientWidth - totalPadding;
          const scrollWidth = viewPortRef.current.scrollWidth - totalPadding;
          const scrollRatio = clientWidth / scrollWidth;
          const newScrollWidth = clientWidth * scrollRatio;
          return Math.max(newScrollWidth, scrollMinSize);
        }
        return 0;
      };
    }, [viewPortRef.current]);

    const detectResizeElement = new ResizeObserver(() => {
      requestAnimationFrame(() => {
        setScrollSize({
          ySize: calculateScrollHeight(),
          xSize: calculateScrollWidth(),
        });
      });
    });

    useEffect(() => {
      if (!isCalculating) {
        updateOverflowState();
      }
    }, [isCalculating]);

    useEffect(() => {
      if (viewPortRef.current) {
        detectResizeElement.observe(viewPortRef.current);

        requestAnimationFrame(() => {
          setScrollSize({
            ySize: calculateScrollHeight(),
            xSize: calculateScrollWidth(),
          });
        });

        return () => {
          detectResizeElement.disconnect();
        };
      }
    }, []);

    useEffect(() => {
      if (scrollSize.xSize || scrollSize.ySize) {
        finalizeCalculation();
      }
    }, [scrollSize]);

    useEffect(() => {
      if (viewPortRef.current) {
        requestAnimationFrame(() => {
          setScrollSize({
            ySize: calculateScrollHeight(),
            xSize: calculateScrollWidth(),
          });
        });
      }
    }, [viewPortRef.current?.scrollHeight, viewPortRef.current?.scrollWidth]);

    useEffect(() => {
      const calculateYScrollPosition = () => {
        if (viewPortRef.current) {
          const scrollPercent = Math.round(
            (viewPortRef.current.scrollTop /
              (viewPortRef.current?.scrollHeight - viewPortRef.current?.clientHeight)) *
              100,
          );

          const currentScrollHeight = viewPortRef.current?.clientHeight - scrollSize.ySize;
          return Math.round((currentScrollHeight * scrollPercent) / 100);
        }
        return 0;
      };

      const calculateXScrollPosition = () => {
        if (viewPortRef.current) {
          const scrollPercent = Math.round(
            (viewPortRef.current.scrollLeft /
              (viewPortRef.current?.scrollWidth - viewPortRef.current?.clientWidth)) *
              100,
          );

          const currentScrollWidth = viewPortRef.current?.clientWidth - scrollSize.xSize;
          return Math.round((currentScrollWidth * scrollPercent) / 100);
        }
        return 0;
      };

      const handleScroll = () => {
        updateOverflowState();

        if (viewPortRef.current) {
          const scrollTop = viewPortRef.current.scrollTop;

          setPosition({
            yPosition: calculateYScrollPosition(),
            xPosition: calculateXScrollPosition(),
          });
          onScroll?.(scrollTop);
        }
      };

      const viewPort = viewPortRef.current;

      if (viewPort) {
        viewPort.addEventListener('scroll', handleScroll);
      }

      return () => {
        if (viewPort) {
          viewPort.removeEventListener('scroll', handleScroll);
        }
      };
    }, [isDragging, scrollSize]);

    useEffect(() => {
      const handleMouseMove = (e: MouseEvent) => {
        if (isDragging) {
          if (!viewPortRef.current) {
            return;
          }

          if (draggingRef.current?.type === 'y') {
            const initialY = draggingRef.current.initialY || 0;
            const deltaY = e.clientY - initialY;
            const scrollRatio =
              (viewPortRef.current.scrollHeight - viewPortRef.current.clientHeight) /
              (viewPortRef.current.clientHeight - scrollSize.ySize);
            viewPortRef.current.scrollTop += deltaY * scrollRatio;
            draggingRef.current.initialY = e.clientY;
          } else if (draggingRef.current?.type === 'x') {
            const initialX = draggingRef.current.initialX || 0;
            const deltaX = e.clientX - initialX;
            const scrollRatio =
              (viewPortRef.current.scrollWidth - viewPortRef.current.clientWidth) /
              (viewPortRef.current.clientWidth - scrollSize.xSize);
            viewPortRef.current.scrollLeft += deltaX * scrollRatio;
            draggingRef.current.initialX = e.clientX;
          }
        }
      };

      const handleMouseUp = () => {
        setIsDragging(false);
        draggingRef.current = null;
      };

      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);

      return () => {
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);
      };
    }, [isDragging]);

    useEffect(() => {
      const handleYMouseDown = (e: MouseEvent) => {
        setIsDragging(true);
        draggingRef.current = { type: 'y', initialY: e.clientY };
      };

      const handleXMouseDown = (e: MouseEvent) => {
        setIsDragging(true);
        draggingRef.current = { type: 'x', initialX: e.clientX };
      };

      const yScroll = yScrollRef.current;
      const xScroll = xScrollRef.current;

      if (yScroll) {
        yScroll.addEventListener('mousedown', handleYMouseDown);
      }

      if (xScroll) {
        xScroll.addEventListener('mousedown', handleXMouseDown);
      }

      return () => {
        if (yScroll) {
          yScroll.removeEventListener('mousedown', handleYMouseDown);
        }

        if (xScroll) {
          xScroll.removeEventListener('mousedown', handleXMouseDown);
        }
      };
    }, []);

    const draggingRef = useRef<{ type: 'y' | 'x'; initialX?: number; initialY?: number } | null>(
      null,
    );

    const scrollTo = (index: number, behavior: ScrollBehavior = 'smooth') => {
      if (viewPortRef.current) {
        viewPortRef.current.scrollTo({
          top: index * 32,
          behavior,
        });
      }
    };

    const updateOverflowState = () => {
      if (viewPortRef.current) {
        const isOverflowY = viewPortRef.current.scrollHeight > viewPortRef.current.clientHeight;
        const isOverflowX = viewPortRef.current.scrollWidth > viewPortRef.current.clientWidth;
        setOverflowState({ isOverflowY, isOverflowX });
      } else {
        setOverflowState({ isOverflowY: false, isOverflowX: false });
      }
    };

    const handleYMouseDown: MouseEventHandler<HTMLDivElement> = (e) => {
      setIsDragging(true);
      draggingRef.current = { type: 'y', initialY: e.clientY };
    };

    const handleXMouseDown: MouseEventHandler<HTMLDivElement> = (e) => {
      setIsDragging(true);
      draggingRef.current = { type: 'x', initialX: e.clientX };
    };

    useImperativeHandle(ref, () => ({
      scrollTo,
    }));

    return (
      <div className={customTwMerge('ScrollbarWrap', className)} style={style} {...props}>
        <Children className='viewPort' ref={viewPortRef}>
          {children}
        </Children>
        {!disabledY && overflowState.isOverflowY && (
          <div
            className='yScrollbar opacity-100'
            style={{
              zIndex: disabledY ? -2 : 2,
            }}
            ref={yScrollbarRef}>
            <div
              className='yThumb'
              ref={yScrollRef}
              onMouseDown={handleYMouseDown}
              style={{
                transform: `translateY(${scrollPosition.yPosition}px)`,
                height: `${scrollSize.ySize}px`,
              }}
            />
          </div>
        )}
        {!disabledX && overflowState.isOverflowX && (
          <div
            className='xScrollbar opaicty-100'
            style={{
              zIndex: disabledX ? -2 : 2,
            }}
            ref={xScrollbarRef}>
            <div
              className='xThumb'
              ref={xScrollRef}
              onMouseDown={handleXMouseDown}
              style={{
                transform: `translateX(${scrollPosition.xPosition}px)`,
                width: `${scrollSize.xSize}px`,
              }}
            />
          </div>
        )}
      </div>
    );
  },
);

AlwaysVisibleScrollbar.displayName = 'AlwaysVisibleScrollbar';

export default AlwaysVisibleScrollbar;
