import { customTwMerge } from '@tailwind-base/utils/custom-tw-merge';
import Icon, { type IconProps } from 'afterdoc-design-system/components/Foundations/Icon/Icon';
import type { CSSProperties, ReactNode } from 'react';

type ButtonSize = 'small' | 'big';
type EnableBtnColors = 'blue' | 'red' | 'purple' | 'secondary' | 'transparent';

const CONTAINED_BUTTON_COLORS_STYLE: {
  [key in EnableBtnColors]: {
    bgStyle: string;
    textStyle: string;
    borderStyle?: string;
  };
} = {
  blue: {
    bgStyle: 'bg-blue500 hover:bg-blue600 focus:bg-blue400 active:bg-blue700 disabled:bg-disabled',
    textStyle: 'text-white50',
    borderStyle: 'border-blue500 disabled:border-disabled',
  },
  red: {
    bgStyle: 'bg-red500 hover:bg-red600 focus:bg-red400 active:bg-red700 disabled:bg-disabled',
    textStyle: 'text-white50',
    borderStyle: 'border-red500 disabled:border-disabled',
  },
  purple: {
    bgStyle: 'bg-purple500 hover:bg-purple600 active:bg-purple500 disabled:bg-disabled',
    textStyle: 'text-white50',
    borderStyle: 'border-purple500 disabled:border-disabled',
  },
  secondary: {
    bgStyle:
      'bg-white200 hover:bg-white400 focus:bg-white200 active:bg-white500 disabled:bg-disabled border-white100 disabled:border-disabled',
    textStyle: 'text-white800 disabled:text-white50',
  },
  transparent: {
    bgStyle: 'bg-transparent',
    textStyle: 'text-black200 disabled:text-disabled',
  },
};

const CONTAINED_BUTTON_SIZES: {
  [key in ButtonSize]: ButtonSize;
} = {
  small: 'small',
  big: 'big',
};

export const CONTAINED_BUTTON_COLORS = Object.keys(CONTAINED_BUTTON_COLORS_STYLE).map((key) =>
  key.replace('Style', ''),
) as EnableBtnColors[];

export const CONTAINED_BUTTON_SIZES_ARR = Object.keys(CONTAINED_BUTTON_SIZES) as ButtonSize[];

export interface ContainedButtonProps<T extends ButtonSize> {
  children: ReactNode;
  onClick?: () => void;
  disabled?: boolean;
  buttonSize?: T;
  iconProps?: T extends 'big' ? IconProps : never;
  btnColor?: EnableBtnColors;
  type?: 'button' | 'submit' | 'reset';
  className?: string;
  onSubmit?: () => void;
  style?: CSSProperties;
}

export default function ContainedButton({
  children,
  onClick,
  onSubmit,
  disabled,
  buttonSize = 'big',
  iconProps,
  btnColor = 'blue',
  type = 'button',
  className,
  style,
}: ContainedButtonProps<ButtonSize>) {
  const buttonSizeClassName =
    buttonSize === 'big'
      ? 'text-Body13 rounded-r10 py-5 px-19'
      : 'text-Body11 rounded-r6 py-2 px-13';

  return (
    <button
      disabled={disabled}
      className={customTwMerge(
        'h-fit w-fit select-none border',
        iconProps && 'flex-row-center gap-4',
        CONTAINED_BUTTON_COLORS_STYLE[btnColor].bgStyle,
        CONTAINED_BUTTON_COLORS_STYLE[btnColor].textStyle,
        CONTAINED_BUTTON_COLORS_STYLE[btnColor].borderStyle,
        buttonSizeClassName,
        className,
      )}
      onSubmit={onSubmit}
      onClick={onClick}
      type={type}
      style={style}>
      {children}
      {iconProps && buttonSize === 'big' && (
        <Icon color={iconProps.color ?? 'white50'} {...iconProps} />
      )}
    </button>
  );
}
