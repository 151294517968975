import { customTwMerge } from '@tailwind-base/utils/custom-tw-merge';
import IconButton from 'afterdoc-design-system/components/Atoms/Button/IconButton';
import Icon from 'afterdoc-design-system/components/Foundations/Icon/Icon';
import { alertService } from 'afterdoc-design-system/components/Molecules/Alert/Alert.service';
import { AnimatePresence, motion } from 'framer-motion';
import { type ReactNode, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export interface AlertProps {
  contents: ReactNode;
  id?: string;
  title?: string;
  type?: 'default' | 'warning';
  navigatePath?: string;
  onClickAlert?: () => void;
  onClose?: () => void;
  bottomButtons?: ReactNode;
}

const Alert = ({
  id,
  navigatePath,
  contents,
  title,
  type,
  onClickAlert,
  bottomButtons,
  onClose,
}: AlertProps) => {
  const navigate = useNavigate();

  const [isVisible, setIsVisible] = useState(false);

  const modalRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  const handleClose = () => {
    setIsVisible(false);
    setTimeout(() => {
      if (id) {
        alertService.popById(id);
      } else {
        alertService.popAll();
      }
      if (onClose) onClose();
    }, 100);
  };

  useEffect(() => {
    setIsVisible(true);
    return () => {
      setIsVisible(false);
    };
  }, []);

  return (
    <AnimatePresence>
      {isVisible && (
        <motion.div
          className='fixed top-0 right-[10px] z-[9999] w-[400px] select-none'
          ref={modalRef}
          initial={{ opacity: 0, x: 100, y: 10 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: 100 }}
          transition={{ duration: 0.1 }}>
          <div
            onClick={(e) => {
              e.stopPropagation();

              onClickAlert?.();

              if (navigatePath) {
                navigate(navigatePath);
                id ? alertService.popById(id) : alertService.popAll();
              }
            }}
            className={customTwMerge(
              'relative cursor-pointer rounded-r16 pt-16 pr-20 pb-14 pl-24 shadow-spray',
              type === 'warning' ? '!bg-opacity-80 bg-red50' : 'bg-white50',
            )}
            id={id}
            ref={contentRef}>
            <div className='flex w-full items-center justify-between'>
              <div className='flex items-center gap-8'>
                {type === 'warning' && <Icon name='warning' color='red500' size={20} />}
                <div className='text-Header14 text-black700'>{title}</div>
              </div>
              <IconButton
                icon='close'
                size={20}
                color='black500'
                onClick={(e) => {
                  e.stopPropagation();
                  handleClose();
                }}
              />
            </div>
            <div className='w-full max-w-[356px] py-4 text-Body12 text-black700'>{contents}</div>
            {bottomButtons}
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default Alert;
