import { useClickOutside } from '@shared-hooks/use-click-outside';
import { SHARED_UTILS } from '@shared-utils/utils';
import { customTwMerge } from '@tailwind-base/utils/custom-tw-merge';
import { useDropdownKeyboardEvent } from 'afterdoc-design-system/components/Atoms/Dropdown/hooks/use-dropdown-keyboard-events';
import Scrollbar from 'afterdoc-design-system/components/Atoms/Scrollbar/Scrollbar';
import FilledTag from 'afterdoc-design-system/components/Atoms/Tag/FilledTag';
import Icon from 'afterdoc-design-system/components/Foundations/Icon/Icon';
import {
  type CSSProperties,
  type RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { v4 as uuidv4 } from 'uuid';
import type { TagProperty } from './types/tag-dropdown';

export interface TagDropdownProps {
  tags: TagProperty[];
  isToggle: boolean;
  id?: string;
  searchText?: string;
  selectedIndex?: number;
  onSelect?: (index: number) => void;
  handleToggle: (isToggle?: boolean) => void;
  redTextIndices?: number[];
  wrapperClassName?: string;
  tagsClassName?: string;
  width?: number | string;
  wrapperStyle?: CSSProperties;
  tagStyle?: CSSProperties;
  ignoreRefs?: RefObject<HTMLElement>[];
  focusedIndex?: number;
  onFocusChange?: (index: number) => void;
  tagMaxTextLength?: number | 'full';
  isDefaultFocus?: boolean;
  customFocusScrollHandler?: (focusedIndex: number) => number;
  customSelectedScrollHandler?: (selectedIndex: number) => number;
  handleClickOutside?: () => void;
}

export default function TagDropdown({
  tags,
  isToggle,
  id,
  searchText,
  selectedIndex,
  onSelect,
  handleToggle,
  redTextIndices = [],
  wrapperClassName,
  tagsClassName,
  width,
  wrapperStyle,
  tagStyle,
  ignoreRefs,
  focusedIndex: initialFocusedIndex,
  onFocusChange,
  tagMaxTextLength,
  isDefaultFocus = false,
  customFocusScrollHandler,
  customSelectedScrollHandler,
  handleClickOutside,
}: TagDropdownProps) {
  const dropdownId = useRef(id ?? uuidv4()).current;
  const dropdownRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const scrollbarRef = useRef<{
    scrollTo: (index: number, behavior?: ScrollBehavior, height?: number) => void;
  } | null>(null);

  const [focusedIndex, setFocusedIndex] = useState<number | undefined>(initialFocusedIndex);

  const handleEscape = useCallback(() => {
    handleToggle(false);
  }, [handleToggle]);

  const handleDropdownKeyDown = useCallback(
    (event: KeyboardEvent) => {
      event.preventDefault();

      if (!contentRef.current) return;

      useDropdownKeyboardEvent({
        keycode: event.key,
        listEl: contentRef.current,
        onEnter: handleEnter,
        onEsc: handleEscape,
        focusedIndex,
        setFocusedIndex,
      });
    },
    [handleEscape, focusedIndex],
  );

  const handleEnter = (focusedItemId: string) => {
    if (!tags.length) return;

    const selectedIndex = tags.findIndex(
      (item, index) => item.id === focusedItemId && index === focusedIndex,
    );

    if (selectedIndex !== -1) {
      onSelect?.(selectedIndex);
      setFocusedIndex(0);
    }
  };

  const openDropdown = () => {
    const event = new CustomEvent('dropdownOpen', { detail: { dropdownId } });
    window.dispatchEvent(event);
    handleToggle(true);
  };

  const defaultFocusScrollHandler = useCallback(() => {
    if (focusedIndex !== undefined && scrollbarRef.current) {
      scrollbarRef.current.scrollTo(focusedIndex, 'auto', 33);
    }
  }, [focusedIndex]);

  const defaultSelectedScrollHandler = useCallback(() => {
    const selectedElement = contentRef.current?.querySelector(
      `[aria-selected='true']`,
    ) as HTMLElement;
    if (selectedElement && scrollbarRef.current) {
      const index = Number(selectedElement.getAttribute('item-index'));
      scrollbarRef.current.scrollTo(index, 'auto', 33);
    }
  }, [selectedIndex]);

  useClickOutside({
    id: dropdownId,
    ref: dropdownRef,
    contentRef,
    onClose: () => {
      handleClickOutside?.();
      handleToggle(false);
    },
    ignoreRefs: ignoreRefs ?? [],
  });

  useEffect(() => {
    if (searchText && searchText.length > 0 && tags.length > 0 && focusedIndex === -1) {
      setFocusedIndex(0);
    }
  }, [tags, searchText]);

  useEffect(() => {
    if (isToggle) {
      openDropdown();
    }
  }, [isToggle]);

  useEffect(() => {
    const keydownListener = (event: KeyboardEvent) => {
      if (
        event.key === 'Escape' ||
        event.key === 'ArrowUp' ||
        event.key === 'ArrowDown' ||
        event.key === 'Enter'
      ) {
        handleDropdownKeyDown(event);
      }
    };

    window.addEventListener('keydown', keydownListener, true);
    return () => {
      window.removeEventListener('keydown', keydownListener, true);
    };
  }, [handleDropdownKeyDown]);

  useEffect(() => {
    if (!focusedIndex) return;

    const scrollPosition = customFocusScrollHandler
      ? customFocusScrollHandler(focusedIndex)
      : focusedIndex;

    if (scrollbarRef.current && scrollPosition !== undefined) {
      scrollbarRef.current.scrollTo(scrollPosition, 'auto', 33);
    } else {
      defaultFocusScrollHandler();
    }
  }, [focusedIndex, customFocusScrollHandler, defaultFocusScrollHandler]);

  useEffect(() => {
    if (!selectedIndex) return;

    const scrollPosition = customSelectedScrollHandler
      ? customSelectedScrollHandler(selectedIndex)
      : selectedIndex;

    if (scrollbarRef.current && scrollPosition !== undefined) {
      scrollbarRef.current.scrollTo(scrollPosition, 'auto', 33);
    } else {
      defaultSelectedScrollHandler();
    }
  }, [selectedIndex, customSelectedScrollHandler, defaultSelectedScrollHandler]);

  useEffect(() => {
    if (focusedIndex === undefined && tags.length > 0) {
      const newFocusedIndex =
        selectedIndex !== undefined ? selectedIndex : isDefaultFocus ? 0 : undefined;
      setFocusedIndex(newFocusedIndex);
    }
  }, [tags.length, selectedIndex, focusedIndex]);

  useEffect(() => {
    if (!tags.length) return;

    const selectedElement = contentRef.current?.querySelector(
      `[aria-selected='true']`,
    ) as HTMLElement;
    if (selectedElement && scrollbarRef.current) {
      const index = Number(selectedElement.getAttribute('item-index'));

      if (tags.length >= 5) {
        scrollbarRef.current.scrollTo(index - 2, 'auto');
      } else {
        scrollbarRef.current.scrollTo(index, 'auto');
      }
    }
  }, [tags]);

  useEffect(() => {
    const handleDropdownOpen = (event: CustomEvent) => {
      if (event.detail.dropdownId !== dropdownId && isToggle) {
        handleToggle(false);
      }
    };

    window.addEventListener('dropdownOpen', handleDropdownOpen as EventListener);

    return () => {
      window.removeEventListener('dropdownOpen', handleDropdownOpen as EventListener);
    };
  }, [dropdownId, isToggle, handleToggle]);

  return (
    <div ref={dropdownRef} className='relative'>
      <div
        ref={contentRef}
        className={customTwMerge(
          'absolute z-10 mt-2 select-none rounded-r6 bg-white50 py-4 shadow-modal ring-1 ring-black ring-opacity-5',
          wrapperClassName,
        )}
        data-ignore-click-outside={true}
        style={{
          height: tags.length > 0 ? 33 * Math.min(tags.length, 5) + 8 : 40,
          width: SHARED_UTILS.css.getCssSizeValue(width),
          ...wrapperStyle,
        }}>
        {tags.length > 0 ? (
          <Scrollbar ref={scrollbarRef}>
            <div aria-orientation='vertical' aria-labelledby='tags-menu'>
              {tags.map((option, index) => (
                <div
                  style={tagStyle}
                  key={option.id}
                  tabIndex={index}
                  onClick={() => {
                    onSelect?.(index);
                  }}
                  onMouseEnter={() => onFocusChange?.(index)}
                  className={customTwMerge(
                    `block cursor-pointer px-10 py-7 focus:bg-blue50 focus:outline-none ${
                      redTextIndices.includes(index) ? 'text-red500' : 'text-black500'
                    } flex items-center justify-between hover:bg-blue50`,
                    focusedIndex === index ? 'bg-blue50' : '',
                    selectedIndex === index ? 'text-Header12' : 'text-Body12',
                    tagsClassName,
                  )}
                  id={option.id}
                  item-index={index}
                  aria-labelledby='tag-option'
                  aria-selected={selectedIndex === index ? 'true' : undefined}>
                  <FilledTag maxTextLength={tagMaxTextLength} bgColor={option.color ?? '#F5F5F5'}>
                    {option.name}
                  </FilledTag>

                  {selectedIndex === index && <Icon name='done' color='blue500' size={16} />}
                </div>
              ))}
            </div>
          </Scrollbar>
        ) : (
          <div className='-mt-4 p-16 text-Caption9 text-black200'>*등록 가능 태그가 없습니다.</div>
        )}
      </div>
    </div>
  );
}
