import type { AlertListProps } from 'afterdoc-design-system/components/Molecules/Alert/Alert.type';
import Portal from 'afterdoc-design-system/shared/Portal/Portal';
import { useEffect, useState } from 'react';
import Alert, { type AlertProps } from './Alert';
import { alertService } from './Alert.service';

export default function AlertManager() {
  const [alerts, setAlerts] = useState<AlertProps[]>([]);

  useEffect(() => {
    const handlePush = (event: CustomEvent<AlertListProps>) => {
      setAlerts((prev) => [...prev, event.detail]);
    };

    const handlePop = () => {
      setAlerts((prev) => prev.slice(0, -1));
    };

    const handlePopById = (event: CustomEvent<string>) => {
      setAlerts((prev) => prev.filter((alert) => alert.id !== event.detail));
    };

    const handlePopAll = () => {
      setAlerts([]);
    };

    document.addEventListener('pushAlert', handlePush as EventListener);
    document.addEventListener('popAlert', handlePop);
    document.addEventListener('popAlertById', handlePopById as EventListener);
    document.addEventListener('popAllAlerts', handlePopAll);

    return () => {
      document.removeEventListener('pushAlert', handlePush as EventListener);
      document.removeEventListener('popAlert', handlePop);
      document.removeEventListener('popAlertById', handlePopById as EventListener);
      document.removeEventListener('popAllAlerts', handlePopAll);
    };
  }, []);

  return (
    <Portal>
      {alerts.map((alert) => (
        <Alert
          key={alert.id}
          id={alert.id}
          navigatePath={alert.navigatePath}
          type={alert.type}
          title={alert.title}
          onClickAlert={alert.onClickAlert}
          contents={alert.contents}
          bottomButtons={alert.bottomButtons}
          onClose={() => {
            alert.id ? alertService.popById(alert.id) : alertService.popAll();
          }}
        />
      ))}
    </Portal>
  );
}
