import { SHARED_UTILS } from '@shared-utils/utils';
import { Color } from '@tailwind-base/styles/color';
import { customTwMerge } from '@tailwind-base/utils/custom-tw-merge';
import _BaseTag, { type BaseTagProps } from './shared/_BaseTag';

export interface FilledTagProps
  extends Omit<BaseTagProps, 'borderColor' | 'borderWidth' | 'textStyle'> {}

export default function FilledTag({
  children,
  tagSize = 'small',
  bgColor = Color.white400,
  textColor,
  iconProps,
  onClick,
  maxTextLength,
  className,
  style,
  ...props
}: FilledTagProps) {
  const isDefault = bgColor === Color.white400 && !className?.includes('cursor-pointer');
  const calculatedTextColor = SHARED_UTILS.text.makeTextColorWithBgColor({
    bgColor,
  });

  return (
    <_BaseTag
      tagSize={tagSize}
      bgColor={bgColor}
      textColor={textColor ?? calculatedTextColor}
      iconProps={
        iconProps
          ? {
              ...iconProps,
              customColor: iconProps.color ? undefined : (textColor ?? calculatedTextColor),
            }
          : undefined
      }
      onClick={onClick}
      className={customTwMerge(
        isDefault && 'hover:!border-white600 hover:!bg-white600',
        'pl-8',
        tagSize === 'small' ? 'py-3' : 'py-1',
        iconProps ? 'pr-6' : 'pr-8',
        className,
      )}
      style={style}
      maxTextLength={maxTextLength}
      {...props}>
      {children}
    </_BaseTag>
  );
}
