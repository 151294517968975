import { SHARED_UTILS } from '@shared-utils/utils';
import { customTwMerge } from '@tailwind-base/utils/custom-tw-merge';
import type { LabelTextProps } from 'afterdoc-design-system/components/Atoms/LabelText/LabelText';
import LabelText from 'afterdoc-design-system/components/Atoms/LabelText/LabelText';
import FilledTag from 'afterdoc-design-system/components/Atoms/Tag/FilledTag';
import {
  forwardRef,
  useCallback,
  useEffect,
  useId,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import TagDropdown from '../TagDropdown';
import '../TextFieldSelectBoxTagDropdown.scss';
import type { TagProperty } from '../types/tag-dropdown';

export interface TextFieldSelectBoxTagDropdownProps {
  width?: number | string;
  placeholder?: string;
  tagOptions: TagProperty[];
  defaultSelectedTags?: TagProperty[];
  label?: LabelTextProps;
  onSelect?: (index: number) => void;
  onSelectedTagsChange?: (tags: TagProperty[]) => void;
  className?: string;
  fieldWrapperClassName?: string;
  disabled?: boolean;
}

const MultipleTextFieldSelectBoxTagDropdown = forwardRef<
  HTMLDivElement,
  TextFieldSelectBoxTagDropdownProps
>(
  (
    {
      width,
      onSelect,
      tagOptions,
      defaultSelectedTags = [],
      onSelectedTagsChange,
      label,
      disabled,
      placeholder,
      className,
      fieldWrapperClassName,
    },
    ref,
  ) => {
    const uniqueId = useId();
    const inputRef = useRef<HTMLDivElement>(null);
    const ignoreRef = useRef(null);

    const [isToggle, setIsToggle] = useState(false);
    const [selectedTags, setSelectedTags] = useState<TagProperty[]>([]);
    const [inputValue, setInputValue] = useState('');
    const [immediateInputValue, setImmediateInputValue] = useState('');
    const [filteredTags, setFilteredTags] = useState(tagOptions);
    const [isComposing, setIsComposing] = useState(false);

    const initialRender = useRef(true);

    useImperativeHandle(ref, () => inputRef.current as HTMLDivElement);

    const handleSelect = (index: number) => {
      if (disabled) return;

      const selectedTag = filteredTags[index];
      if (!selectedTags.some((tag) => tag.name === selectedTag.name)) {
        const newSelectedTags = [...selectedTags, selectedTag];
        setSelectedTags(newSelectedTags);
        onSelectedTagsChange?.(newSelectedTags);
        setInputValue('');
        setImmediateInputValue('');
        if (inputRef.current) {
          inputRef.current.textContent = '';
        }
        onSelect?.(index);
        setIsToggle(true);
      }
      if (inputRef.current) {
        inputRef.current.textContent = '';
        inputRef.current.blur();
        const range = document.createRange();
        const sel = window.getSelection();
        range.selectNodeContents(inputRef.current);
        range.collapse(false);
        sel?.removeAllRanges();
        sel?.addRange(range);
        inputRef.current.focus();
      }
    };

    const handleToggle = () => {
      setIsToggle((prev) => !prev);
    };

    const handleKeyDown = useCallback(
      (e: React.KeyboardEvent) => {
        if (disabled) return;

        const selection = window.getSelection();
        if (e.key === 'Enter') {
          e.preventDefault();

          if (isToggle && filteredTags.length > 0) {
            handleSelect(0);
          }
          setIsToggle(true);
          return;
        }

        if (e.key === 'Backspace') {
          if (selection?.toString() || e.ctrlKey || e.metaKey) {
            e.preventDefault();
            setSelectedTags([]);
            onSelectedTagsChange?.([]);
            setInputValue('');
            setImmediateInputValue('');
            if (inputRef.current) {
              inputRef.current.textContent = '';
            }
            return;
          }

          if (inputValue === '' && selectedTags.length > 0) {
            e.preventDefault();
            const newSelectedTags = selectedTags.slice(0, -1);
            setSelectedTags(newSelectedTags);
            onSelectedTagsChange?.(newSelectedTags);
            setIsToggle(true);
          }
          return;
        }

        if (e.key === 'a' && (e.ctrlKey || e.metaKey)) {
          e.preventDefault();
          if (inputRef.current) {
            const range = document.createRange();
            range.selectNodeContents(inputRef.current);
            const sel = window.getSelection();
            sel?.removeAllRanges();
            sel?.addRange(range);
          }
        }
      },
      [inputValue, selectedTags, isToggle, filteredTags, disabled, onSelectedTagsChange],
    );

    const handleRemoveTag = (tag: string) => {
      if (disabled) return;

      const newSelectedTags = selectedTags.filter((t) => t.name !== tag);
      setSelectedTags(newSelectedTags);
      onSelectedTagsChange?.(newSelectedTags);
      setIsToggle(true);
      inputRef.current?.focus();
    };

    const handleInput = (e: React.FormEvent<HTMLDivElement>) => {
      if (disabled) return;

      if (!isComposing) {
        const text = e.currentTarget?.textContent || '';
        setInputValue(text);
        setImmediateInputValue(text);
      }
    };

    const handleCompositionStart = () => {
      setIsComposing(true);
    };

    const handleCompositionEnd = (e: React.CompositionEvent<HTMLDivElement>) => {
      setIsComposing(false);
      const text = e.currentTarget?.textContent || '';
      setInputValue(text);
      setImmediateInputValue(text);
    };

    const handleFocus = (e: React.FocusEvent<HTMLDivElement>) => {
      if (disabled || initialRender.current) {
        e.preventDefault();

        setTimeout(() => {
          inputRef.current?.blur();
        }, 0);
        initialRender.current = false;
        return;
      }
      setIsToggle(true);
    };

    useEffect(() => {
      const observer = new MutationObserver(() => {
        const text = inputRef.current?.textContent || '';
        setImmediateInputValue(text);
      });

      if (inputRef.current) {
        observer.observe(inputRef.current, {
          childList: true,
          subtree: true,
          characterData: true,
        });
      }

      return () => {
        observer.disconnect();
      };
    }, [inputRef]);

    useEffect(() => {
      setSelectedTags(defaultSelectedTags);
    }, [defaultSelectedTags]);

    useEffect(() => {
      if (inputRef.current) {
        const range = document.createRange();
        const sel = window.getSelection();
        range.selectNodeContents(inputRef.current);
        range.collapse(false);
        sel?.removeAllRanges();
        sel?.addRange(range);
      }
    }, [inputValue]);

    useEffect(() => {
      setFilteredTags(
        tagOptions.filter(
          (tag) =>
            tag.name.toLowerCase().includes(immediateInputValue.toLowerCase()) &&
            !selectedTags.some((selectedTag) => selectedTag.name === tag.name),
        ),
      );
    }, [immediateInputValue, tagOptions, selectedTags]);

    useEffect(() => {
      if (inputRef.current) {
        if (selectedTags.length > 0) {
          inputRef.current.setAttribute('data-placeholder', '');
        } else {
          inputRef.current.setAttribute('data-placeholder', placeholder || '');
        }
      }
    }, [selectedTags, placeholder]);

    useEffect(() => {
      if (!isToggle) {
        setTimeout(() => {
          inputRef.current?.blur();
        }, 0);
      }
    }, [isToggle]);

    return (
      <div className={customTwMerge('flex flex-col', className)}>
        {label && (
          <label htmlFor={uniqueId} className='mb-10 flex items-center text-Header12 text-black500'>
            <LabelText {...label} />
          </label>
        )}
        <div>
          <div
            ref={ignoreRef}
            className={customTwMerge(
              'flex flex-wrap items-center rounded-r10 border px-16',
              'outline-none focus-within:border-blue500',
              'overflow-y-auto',
              'text-field-select-box-tag-dropdown-scroll',
              selectedTags.length > 0 ? 'py-[4.5px]' : 'py-6',
              disabled
                ? 'h-32 cursor-not-allowed border-white600 bg-white200'
                : 'border-white600 bg-white50',
              fieldWrapperClassName,
            )}
            style={{
              width: SHARED_UTILS.css.getCssSizeValue(width),
            }}>
            {selectedTags.map((tag, index) => (
              <FilledTag
                key={index}
                tagSize='small'
                className={customTwMerge('m-1', disabled ? 'cursor-not-allowed' : 'cursor-pointer')}
                iconProps={{
                  name: 'close',
                  size: 12,
                }}
                bgColor={tag.color}
                onClick={(e) => {
                  if (disabled) return;
                  e?.stopPropagation();
                  handleRemoveTag(tag.name);
                }}>
                {tag.name}
              </FilledTag>
            ))}
            <div
              id={uniqueId}
              ref={inputRef}
              className='flex-1 border-none bg-transparent text-Body12 text-black700 placeholder-black200 outline-none placeholder:text-Body12 placeholder:text-black200'
              contentEditable={!disabled}
              onInput={handleInput}
              onKeyDown={handleKeyDown}
              onCompositionStart={handleCompositionStart}
              onCompositionEnd={handleCompositionEnd}
              suppressContentEditableWarning={true}
              onFocus={handleFocus}
              onClick={(e) => {
                if (disabled) {
                  e.preventDefault();
                  inputRef.current?.blur();
                }
              }}
            />
          </div>
          {isToggle && !disabled && (
            <TagDropdown
              width={width}
              tags={filteredTags}
              ignoreRefs={[ignoreRef]}
              isToggle={isToggle}
              handleToggle={handleToggle}
              isDefaultFocus={!!immediateInputValue}
              onSelect={handleSelect}
            />
          )}
        </div>
      </div>
    );
  },
);

MultipleTextFieldSelectBoxTagDropdown.displayName = 'MultipleTextFieldSelectBoxTagDropdown';

export default MultipleTextFieldSelectBoxTagDropdown;
