import type {
  OverlayPageListProps,
  OverlayPageProps,
} from 'afterdoc-design-system/components/Molecules/OverlayPage/OverlayPage.type';
import type { ReactNode } from 'react';

export const overlayPageService = {
  push(children: ReactNode, { id, position, className }: OverlayPageProps): string {
    const overlayPageItem: OverlayPageListProps = {
      id,
      children,
      show: true,
      position,
      className,
    };

    const customEvent = new CustomEvent('pushOverlayPage', { detail: overlayPageItem });
    document.dispatchEvent(customEvent);

    return id;
  },

  pop() {
    const customEvent = new CustomEvent('popOverlayPage');
    document.dispatchEvent(customEvent);
  },

  popById(id: string) {
    const customEvent = new CustomEvent('popOverlayPageById', { detail: id });
    document.dispatchEvent(customEvent);
  },

  popAll() {
    const customEvent = new CustomEvent('popAllOverlayPages');
    document.dispatchEvent(customEvent);
  },
};
