import Portal from 'afterdoc-design-system/shared/Portal/Portal';
import { useState } from 'react';
import ToastComponent from './Toast';
import type { ToastProps } from './Toast.type';
import { useToastCustomEventListener } from './hooks/use-toast-custom-event-listener';

/* loadidng toast 존재할 시 늘 일반 toast 보다 하단에 존재합니다.**/
export default function ToastManager() {
  const [toasts, setToasts] = useState<ToastProps[]>([]);

  /* toast Event 들을 감지하는 훅스 **/
  useToastCustomEventListener({ toasts, setToasts });

  return (
    <Portal>
      <div className='fixed bottom-100 left-0 z-[10000] grid w-full flex-col-reverse justify-center gap-10'>
        {toasts.map((toastProps) => (
          <ToastComponent
            key={toastProps.id}
            style={{
              transition: 'all 300ms ease',
            }}
            {...toastProps}
          />
        ))}
      </div>
    </Portal>
  );
}
