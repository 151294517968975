import { customTwMerge } from '@tailwind-base/utils/custom-tw-merge';
import type { ReactNode } from 'react';

export interface TitleProps {
  title: string;
  subTitle?: string;
  adjacentChildren?: {
    position: 'left' | 'right';
    children: ReactNode;
    className?: string;
  };
  trailingChildren?: ReactNode;
  titleClassName?: string;
  subTitleClassName?: string;
  wrapperClassName?: string;
}

export default function Title({
  title,
  subTitle,
  // Title에 '인접한' 컴포넌트
  adjacentChildren,
  // Title의 오른쪽 끝에 붙는 컴포넌트
  trailingChildren,
  titleClassName,
  subTitleClassName,
  wrapperClassName,
}: TitleProps) {
  return (
    <div
      className={customTwMerge(
        'flex h-fit w-full select-none items-start justify-between px-20 pt-20 pb-8',
        wrapperClassName,
      )}>
      <div
        className={customTwMerge('flex flex-row items-center gap-8', adjacentChildren?.className)}>
        {adjacentChildren && adjacentChildren.position === 'left'
          ? adjacentChildren.children
          : null}
        <div className='flex flex-col gap-4'>
          <h1 className={customTwMerge('text-Header16 text-black700', titleClassName)}>{title}</h1>
          {subTitle && (
            <h2
              className={customTwMerge(
                'whitespace-pre-wrap text-Body11 text-black200',
                subTitleClassName,
              )}>
              {subTitle}
            </h2>
          )}
        </div>
        {adjacentChildren && adjacentChildren.position === 'right'
          ? adjacentChildren.children
          : null}
      </div>
      {trailingChildren}
    </div>
  );
}
