import type { AlertProps } from 'afterdoc-design-system/components/Molecules/Alert/Alert';
import type { AlertListProps } from 'afterdoc-design-system/components/Molecules/Alert/Alert.type';
import { uniqueId } from 'lodash-es';

export const alertService = {
  push({
    contents,
    type,
    navigatePath,
    onClickAlert,
    bottomButtons,
    id,
    ...props
  }: AlertProps): string {
    const uniqId = id || uniqueId();

    const alertItem: AlertListProps = {
      id: uniqId,
      contents,
      type,
      navigatePath,
      onClickAlert,
      bottomButtons,
      ...props,
    };

    const customEvent = new CustomEvent('pushAlert', { detail: alertItem });
    document.dispatchEvent(customEvent);

    return uniqId;
  },

  pop() {
    const customEvent = new CustomEvent('popAlert');
    document.dispatchEvent(customEvent);
  },

  popById(id: string) {
    const customEvent = new CustomEvent('popAlertById', { detail: id });
    document.dispatchEvent(customEvent);
  },

  popAll() {
    const customEvent = new CustomEvent('popAllAlerts');
    document.dispatchEvent(customEvent);
  },
};
