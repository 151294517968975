import { useClickOutside } from '@shared-hooks/use-click-outside';
import { SHARED_UTILS } from '@shared-utils/utils';
import { customTwMerge } from '@tailwind-base/utils/custom-tw-merge';
import IconButton from 'afterdoc-design-system/components/Atoms/Button/IconButton';
import Title, { type TitleProps } from 'afterdoc-design-system/components/Molecules/Title/Title';
import { type ReactNode, useEffect, useRef, useState } from 'react';
import { v4 } from 'uuid';

export interface DialogProps {
  id?: string;
  contents: ReactNode;
  onClose: () => void;
  hasCloseButton?: boolean;
  wrapperClassName?: string;
  width?: number | string;
  isClosable?: boolean;
  titleProps?: TitleProps;
}

export default function Dialog({
  id,
  contents,
  hasCloseButton = false,
  onClose,
  wrapperClassName,
  width,
  isClosable = true,
  titleProps,
}: DialogProps) {
  const dialogRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const [contentWidth, setContentWidth] = useState<number | null>(null);

  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = '';
    };
  }, []);

  useEffect(() => {
    if (contentRef.current) {
      setContentWidth(contentRef.current.scrollWidth);
    }
  }, [contents]);

  useClickOutside({
    id: id ?? v4(),
    ref: dialogRef,
    contentRef,
    onClose: isClosable ? onClose : () => {},
  });

  return (
    <div
      className='fixed top-0 left-0 z-[9999] flex-full-center select-none bg-black900 bg-opacity-20'
      ref={dialogRef}>
      <div
        ref={contentRef}
        className={customTwMerge(
          'relative mt-70 mb-80 flex h-full max-h-[calc(100vh-150px)] flex-col overflow-hidden rounded-r16 bg-white50 shadow-dialog',
          wrapperClassName,
        )}
        id={id}
        style={{
          width: width
            ? SHARED_UTILS.css.getCssSizeValue(width)
            : contentWidth
              ? `${contentWidth}px`
              : 'auto',
        }}>
        {(titleProps || hasCloseButton) && (
          <Title
            title={titleProps?.title ?? ''}
            subTitle={titleProps?.subTitle}
            trailingChildren={
              hasCloseButton &&
              (titleProps?.trailingChildren || (
                <IconButton onClick={onClose} icon='close' size={24} color='black200' />
              ))
            }
            {...titleProps}
          />
        )}
        {contents}
      </div>
    </div>
  );
}
