import { customTwMerge } from '@tailwind-base/utils/custom-tw-merge';
import ContainedButton from 'afterdoc-design-system/components/Atoms/Button/ContainedButton';
import OutlinedButton from 'afterdoc-design-system/components/Atoms/Button/OutlinedButton';
import type { CSSProperties, ComponentProps, ReactNode } from 'react';

export interface DownloadFooterProps {
  downloadButton?: ReactNode;
  downloadButtonProps?: Omit<ComponentProps<typeof OutlinedButton>, 'children'>;
  uploadButton?: ReactNode;
  uploadButtonProps?: Omit<ComponentProps<typeof OutlinedButton>, 'children'>;
  saveButton?: ReactNode;
  saveButtonProps?: Omit<ComponentProps<typeof ContainedButton>, 'children'>;
  onDownload?: () => void;
  onUpload?: () => void;
  onSave?: () => void;
  customLeftButton?: ReactNode;
  customRightButton?: ReactNode;
  className?: string;
  style?: CSSProperties;
}

export default function DownloadFooter({
  downloadButton,
  downloadButtonProps,
  uploadButton,
  uploadButtonProps,
  saveButton,
  saveButtonProps,
  onDownload,
  onUpload,
  onSave,
  customLeftButton,
  customRightButton,
  className,
  style,
}: DownloadFooterProps) {
  return (
    <div
      className={customTwMerge(
        'flex w-full select-none items-center justify-between bg-white50 px-40 py-20 shadow-chat',
        className,
      )}
      style={style}>
      <div className='flex gap-16'>
        {downloadButton && (
          <OutlinedButton
            btnColor='secondary'
            onClick={onDownload}
            iconProps={{
              name: 'tray-download',
              color: 'white700',
              size: 20,
            }}
            {...downloadButtonProps}>
            {downloadButton}
          </OutlinedButton>
        )}
        {uploadButton && (
          <OutlinedButton
            btnColor='secondary'
            onClick={onUpload}
            iconProps={{
              name: 'tray-upload',
              color: 'white700',
              size: 20,
            }}
            {...uploadButtonProps}>
            {uploadButton}
          </OutlinedButton>
        )}
        {customLeftButton}
      </div>
      {saveButton && (
        <ContainedButton btnColor='blue' onClick={onSave} {...saveButtonProps}>
          {saveButton}
        </ContainedButton>
      )}
      {customRightButton}
    </div>
  );
}
