import Portal from 'afterdoc-design-system/shared/Portal/Portal';
import { useEffect, useState } from 'react';
import LocalOverlayPage from './OverlayPage';
import { overlayPageService } from './OverlayPage.service';
import type { OverlayPageListProps } from './OverlayPage.type';

export default function OverlayPageManager() {
  const [overlayPages, setOverlayPages] = useState<OverlayPageListProps[]>([]);

  useEffect(() => {
    const handlePush = (event: CustomEvent<OverlayPageListProps>) => {
      setOverlayPages((prev) => [...prev, event.detail]);
    };

    const handlePop = () => {
      setOverlayPages((prev) => prev.slice(0, -1));
    };

    const handlePopById = (event: CustomEvent<string>) => {
      setOverlayPages((prev) => prev.filter((overlayPage) => overlayPage.id !== event.detail));
    };

    const handlePopAll = () => {
      setOverlayPages([]);
    };

    document.addEventListener('pushOverlayPage', handlePush as EventListener);
    document.addEventListener('popOverlayPage', handlePop);
    document.addEventListener('popOverlayPageById', handlePopById as EventListener);
    document.addEventListener('popAllOverlayPages', handlePopAll);

    return () => {
      document.removeEventListener('pushOverlayPage', handlePush as EventListener);
      document.removeEventListener('popOverlayPage', handlePop);
      document.removeEventListener('popOverlayPageById', handlePopById as EventListener);
      document.removeEventListener('popAllOverlayPages', handlePopAll);
    };
  }, []);

  return (
    <Portal>
      {overlayPages.map((overlayPage) => (
        <LocalOverlayPage
          key={overlayPage.id}
          id={overlayPage.id}
          show={overlayPage.show}
          className={overlayPage.className}
          position={overlayPage.position}
          onClose={() => {
            overlayPage.id
              ? overlayPageService.popById(overlayPage.id)
              : overlayPageService.popAll();
            overlayPage.onClose?.();
          }}>
          {overlayPage.children}
        </LocalOverlayPage>
      ))}
    </Portal>
  );
}
