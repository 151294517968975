import type React from 'react';
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import TextInput, { type TextInputProps } from './TextInput';

export interface SearchInputProps
  extends Omit<TextInputProps, 'leftIcon' | 'rightIconButtonProps'> {
  onDelete?: () => void;
  className?: string;
}

const SearchInput = forwardRef<HTMLInputElement, SearchInputProps>(
  ({ value, onDelete, onChange, ...textInputProps }, ref) => {
    const inputRef = useRef<HTMLInputElement | null>(null);
    const [internalValue, setInternalValue] = useState(value || '');

    useImperativeHandle(ref, () => inputRef.current ?? ({} as HTMLInputElement));

    useEffect(() => {
      if (value !== undefined) {
        setInternalValue(value);
      }
    }, [value]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setInternalValue(e.target.value);
      onChange?.(e);
    };

    const handleClear = () => {
      if (inputRef.current) {
        inputRef.current.value = '';
        inputRef.current.focus();
      }
      setInternalValue('');
      onDelete?.();
      onChange?.({ target: { value: '' } } as React.ChangeEvent<HTMLInputElement>);
    };

    return (
      <TextInput
        ref={inputRef}
        value={internalValue}
        onChange={handleChange}
        inputClassName='rounded-r100'
        leftIconProps={{
          name: 'search',
          size: 20,
          color: 'black900',
        }}
        rightIconButtonProps={
          internalValue
            ? {
                icon: 'close',
                size: 16,
                color: 'black600',
                onClick: handleClear,
              }
            : undefined
        }
        {...textInputProps}
      />
    );
  },
);

export default SearchInput;
