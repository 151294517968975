import { customTwMerge } from '@tailwind-base/utils/custom-tw-merge';
import OutlinedButton, {
  type OutlinedButtonProps,
} from 'afterdoc-design-system/components/Atoms/Button/OutlinedButton';
import type { IconProps } from 'afterdoc-design-system/components/Foundations/Icon/Icon';
import Icon from 'afterdoc-design-system/components/Foundations/Icon/Icon';
import type { CSSProperties } from 'react';

export interface NoDataProps {
  title: string;
  subTitle?: string;
  iconProps?: IconProps;
  bottomButtonProps?: OutlinedButtonProps<'big'>;
  titleClassName?: string;
  subTitleClassName?: string;
  className?: string;
  style?: CSSProperties;
}

export default function NoData({
  title,
  subTitle,
  iconProps = {
    name: 'person-tick',
    size: 48,
    color: 'white600',
  },
  bottomButtonProps,
  titleClassName,
  subTitleClassName,
  className,
  style,
}: NoDataProps) {
  return (
    <div
      className={customTwMerge('flex-col-center select-none text-center', className)}
      style={style}>
      <Icon {...iconProps} />
      <div
        id='title'
        className={customTwMerge('mt-10 font-bold text-Header14 text-black500', titleClassName)}>
        {title}
      </div>
      {subTitle && (
        <div
          id='subtitle'
          className={customTwMerge('mt-6 text-Body13 text-black500', subTitleClassName)}>
          {subTitle}
        </div>
      )}
      {bottomButtonProps && <OutlinedButton className='mt-10' {...bottomButtonProps} />}
    </div>
  );
}
